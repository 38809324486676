<template>
  <div style="max-width: 1000px; margin: auto">
    <br />

    <Dialog
      :visible.sync="popupdisplay"
      :contentStyle="{ overflow: 'visible' }"
    >
      <template #header>
        <h2>Ajouter un document</h2>
      </template>
      <img
        style="
          margin-top: 25px;
          max-height: 100px;
          margin-left: 25px;
          margin-right: 25px;
        "
        v-bind:src="docurl"
      />
      <h3>Choisir un fichier à ajouter</h3>
      <UploadCloudinary folder="documents" @result="onUploaded($event)" />
      <h3>Titre</h3>
      <InputText
        style="width: 100%; min-width: 400px"
        v-model="doctitle"
        :autoResize="true"
      />
      <h3>Description</h3>
      <Textarea
        style="width: 100%"
        v-model="docdescription"
        :autoResize="true"
      />
      <template #footer>
        <Button
          label="Annuler"
          icon="pi pi-times"
          class="p-button-text"
          @click="closeDialog()"
        />
        <Button label="Valider" icon="pi pi-check" autofocus @click="add()" />
      </template>
    </Dialog>
    <Dialog
      :visible.sync="popupdisplaymodify"
      :contentStyle="{ overflow: 'visible' }"
    >
      <template #header>
        <h2>Modifier un document</h2>
      </template>
      <img onerror="this.style.display='none'"
        style="
          margin-top: 25px;
          max-height: 100px;
          margin-left: 25px;
          margin-right: 25px;
        "
        v-bind:src="docurl"
      />
      <h3>Choisir un fichier pour remplacer le document</h3>
      <UploadCloudinary folder="documents" @result="onUploaded($event)" />

      <h3>Titre</h3>
      <InputText
        style="width: 100%; min-width: 400px"
        v-model="doctitle"
        :autoResize="true"
      />
      <h3>Description</h3>
      <Textarea
        style="width: 100%"
        v-model="docdescription"
        :autoResize="true"
      />
      <template #footer>
        <Button
          label="Annuler"
          icon="pi pi-times"
          class="p-button-text"
          @click="closeDialog()"
        />
        <Button
          label="Valider"
          icon="pi pi-check"
          autofocus
          @click="modify()"
        />
      </template>
    </Dialog>

    <Button
      style="margin-bottom: 10px"
      label="Ajouter un document"
      icon="pi pi-plus"
      @click="openDialog()"
    />

    <div>
      <div class="p-grid">
        <Documents
          ref="doc"
          class="p-col"
          v-for="(document, idx) in orderedDoc"
          :key="idx"
          :documentItem="document"/>
      </div>
    </div>
  </div>
</template>


<script>
import Documents from "../components/Documents.vue";
import { store } from "../store";

var moment = require("moment");

export default {
  name: "Documentation",
  components: {
    Documents,
  },
  computed:{
    orderedDoc()
    {
      var docs = [...store.state.documents];
      docs.sort(function (x, y) {
        return (
          moment(y.timestamp, "DD/MM/YYYY HH:mm:ss") -
          moment(x.timestamp, "DD/MM/YYYY HH:mm:ss")
        );
      });
      return docs;
    },
  },
  methods: {
    ModifyDoc(docid) {
      var doc = {};
      store.state.documents.forEach((element) => {
        if (element.id === docid) {
          doc = element;
        }
      });
      this.doctitle = doc.name;
      this.docdescription = doc.description;
      this.popupdisplaymodify = true;
      this.popupdisplay = false;
      this.docid = docid;
      this.docurl = doc.url;
    },
    DeleteDoc(doc) {
      store.dispatch("deleteDocument", doc.id);
    },
    closeDialog() {
      this.popupdisplay = false;
      this.popupdisplaymodify = false;
    },
    openDialog() {
      this.doctitle = "";
      this.docdescription = "";
      this.popupdisplay = true;
      this.popupdisplaymodify = false;
      this.docurl = "";
    },
    add() {
      this.confirmDocument(false);
    },
    modify() {
      this.confirmDocument(true);
    },
    confirmDocument(ismodify) {
      if (this.docurl === "") {
        this.$toast.add({
          severity: "error",
          summary: "Pas de média téléchargé",
          detail: "Veuillez ajouter un document",
          life: 3000,
        });
      } else {
        var title = this.original_name;
        if (this.doctitle !== undefined) title = this.doctitle;
        var description = "";
        if (this.docdescription !== undefined)
          description = this.docdescription;

        var doc = {
          description: description,
          name: title,
          userid: store.state.userProfile.id,
          url: this.docurl,
          timestamp: moment().format("DD/MM/YYYY hh:mm:ss"),
        };
        if (ismodify) {
          doc.id = this.docid;
          store.dispatch("updateDocument", doc);
          this.popupdisplaymodify = false;
        } else {
          store.dispatch("addDocument", doc);
          this.popupdisplay = false;
        }

        this.$toast.add({
          severity: "success",
          summary: "Document enregistré",
          detail: "Base bien mise à jour",
          life: 3000,
        });
      }
    },
    onUploaded(e) {
      this.docurl = e.secure_url;
      this.original_name = e.original_filename;
      if (this.doctitle === "") this.doctitle = e.original_filename;
    },
  },
  created() {
    store.dispatch("bindDocuments");
  },
  data() {
    return {
      docdescription: "",
      doctitle: "",
      popupdisplay: false,
      popupdisplaymodify: false,
      docurl: "",
      original_name: "",
      docid: "",
    };
  },
};
</script>
