<template>
  <div>
    <Card  style="width: 20em;word-wrap: break-word;">
      <template #header>
        <img
          style="
            margin-top: 25px;
            width:80%;
            margin-left: 25px;
            margin-right: 25px;
          "
          v-bind:src="documentItem.url" v-if="documentItem.url.toLowerCase().endsWith('.png') || documentItem.url.toLowerCase().endsWith('.jpg') || documentItem.url.toLowerCase().endsWith('.mp4')|| documentItem.url.toLowerCase().endsWith('.mov')" 
        />
      </template>
      <template #title>{{ documentItem.name }}</template>
      <template #content>{{ documentItem.description }}</template>
      <template #footer>
        <div>
          <Button
            class="p-row"
            label="Modifer"
            icon="pi pi-pencil"
            @click="ModifyDoc(documentItem.id)"
          />
          <a
            v-bind:href="
              documentItem.url.replace('upload/', 'upload/fl_attachment/')
            "
            target="_blank"
          >
            <Button
              style="margin-left: 0.5em; margin-top: 0.5em"
              class="p-row"
              label="Télécharger"
              icon="pi pi-download"
            />
          </a>
          <Button
            style="margin-left: 0.5em; margin-top: 0.5em"
            class="p-row"
            label="Supprimer"
            icon="pi pi-trash"
            @click="DeleteDoc(documentItem)"
          />
        </div>
      </template>
    </Card>
  </div>
</template>

<script>

export default {
  props: ["documentItem"],
  methods: {
    DeleteDoc(doc) {
      this.$parent.DeleteDoc(doc);
    },
    ModifyDoc(docid) {
      this.$parent.ModifyDoc(docid);
    },
  },
};
</script>